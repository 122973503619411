import Vue from 'vue';

import axios from 'axios';
import VueAxios from 'vue-axios';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import router from './router';
import Index from './Index';
import messages from './translations';

import { Signer } from '@waves/signer';

Vue.router = router;
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(VueI18n);

window.nodeURL = 'https://nodes.wavesnodes.com';
// window.nodeURL = 'https://nodes-testnet.wavesnodes.com';

window.signer = new Signer();
// window.signer = new Signer({
//     NODE_URL: window.nodeURL
// });

window.contractAddress = '3P8rLVnwrTf4k5AB3emuduhZZb1yKcitTHy';
window.zombieAddress = '3P7QrWH1ETLfKAJcFHYPJmQusNbDuW98Hop';
// window.contractAddress = '3N5aN5RqVqtb8HbNghuNb1NygjYPFRKfE7E';
// window.zombieAddress = '3MydZBLyjvR1dzf6nzucCbpCDZgaL4zWPwg';

window.madEmpireAddress = '3P8LcmWrr9SFrxwheZQENov5fPHEWtiwroN';
window.madFreeAddress = '3PJXfYMavRVzBDXqsFfwkLdEV7yuF5RToya';
window.madMutationAddress = '3PD5eTW5ZWzTBTph3LQmEt877DPCjQsGwwz';

const i18n = new VueI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: messages,
});

if (window.location.href.indexOf('mint.wavespunks.com') > -1 || window.location.href.indexOf('ost:') > -1 || window.location.href.indexOf('mint-app.pages.dev') > -1)
    new Vue({
        render: h => h(Index),
        el: '#app',
        router,
        i18n,
    });
