<template>
    <div class="data">
        <div>
            <p>2910</p>
            <p>unique<br />punks</p>
        </div>
        <div>
            <p>90</p>
            <p>super rare<br />punks</p>
        </div>
        <!-- <div>
                    <p>1</p>
                    <p>ultra rare<br>punk</p>
                </div> -->
    </div>
</template>

<script>
export default {
    name: "Data",
};
</script>

<style scoped>
@media only screen and (max-width: 550px) {
    .data {
        margin-bottom: 60px !important;
    }

    .data > div {
        flex-direction: column !important;
    }

    .data > div:nth-child(1)::after,
    .data > div:nth-child(2)::after {
        display: none;
    }

    .data > div > p:nth-child(1) {
        margin: 0 !important;
    }

    .data > div > p:nth-child(2) {
        text-align: center;
    }
}

.data {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    max-width: 700px;
    margin: auto;
}

.data > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.data > div > p:nth-child(1) {
    margin-right: 10px;
    font-weight: 400;
    font-size: 40px;
    line-height: 62px;
}

.data > div > p:nth-child(2) {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
}

.data > div:nth-child(1) > p {
    color: white;
}

.data > div:nth-child(2) > p {
    /* color: #FF00CF; */
    color: #CDFF00;
}

.data > div:nth-child(3) > p {
    color: #FFA600;
}
</style>
