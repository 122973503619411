const messages = {
    en: {
        message: {
            mypunks: "My punks",
            price: "CURRENT MINT PRICE",
            way: "CHOOSE THE WAY TO MINT A PUNK",
            mint: "MINT",
            left: "out of 1000 left",
            howto: "Don't know how to mint?",
        }
    },
    es: {
        message: {
            mypunks: "Mis punks",
            price: "PRECIO ACTUAL DEL MINT",
            way: "ELIGE EL DESTINO DONDE QUIERES MINTEAR EL PUNK",
            mint: "MINT",
            left: "disponibles de 1000",
            howto: "¿No sabes cómo Mintear?",
        }
    }
};

export default messages;
