<template>
    <footer>
        <p>Amalgama digital, inc.</p>
        <p>244 Fifth Avenue, Suite A240</p>
        <p>New York, NY 10001</p>
    </footer>
</template>

<script>
export default {
    name: "Footer",
};
</script>

<style scoped>
footer {
    max-width: max-content;
    margin: auto;
}

footer > p {
    margin: 0;
    color: #8b95ad;
    text-align: center;
    font-weight: 400;
    font-size: 9px;
    line-height: 16px;
}
</style>
