import axios from "axios";

async function getSupply(address) {
    let punks_supply = 0;

    await axios.get(`${window.nodeURL}/addresses/data/${address}?key=punks_supply`)
        .then(res => {
            if (res.data[0].key == "punks_supply" && res.data[0].type == "integer")
                punks_supply = res.data[0].value;
        })
        .catch(err => {
            console.error(err);
        });

    return punks_supply;
};

export { getSupply }