import VueRouter from 'vue-router';

import Home from "./pages/Home";
import Zombie from "./pages/Zombie.vue";
import MadEmpire from "./pages/MadEmpire.vue";
import MadFree from "./pages/MadFree.vue";
import MadMutation from "./pages/MadMutation.vue";

const routes = [{
    path: '/',
    name: 'home',
    component: Home
}, {
    path: '/zombie/:locale?',
    name: 'zombie',
    component: Zombie
}, {
    path: '/mad-empire/:locale?',
    name: 'madEmpire',
    component: MadEmpire
}, {
    path: '/mad-free/:locale?',
    name: 'madFree',
    component: MadFree
}, {
    path: '/mad-mutation/:locale?',
    name: 'madMutation',
    component: MadMutation
}];

const router = new VueRouter({
    history: true,
    mode: 'history',
    routes
});

export default router
