<template>
    <div class="zombie">
        <div class="zombie-color">
            <Header
                :img="`/img/zombie/logo.svg`"
                :url="`https://zombie.wavespunks.com/`"
            >
            </Header>
            <div class="mint">
                <p>{{ $t('message.price') }}</p>
                <div class="price">
                    <p>{{ price }}</p>
                    <img src="/img/zombie/waves.svg" />
                </div>
                <p class="way">{{ $t("message.way") }}</p>
                <div
                    class="btn-container"
                    v-if="connect"
                >
                    <button @click="connectKeeper">WAVES KEEPER</button>
                    <button @click="connectEmail">WAVES EMAIL</button>
                </div>
                <div
                    class="btn-container"
                    v-else
                >
                    <button @click="login">{{ $t("message.mint") }}</button>
                </div>
                <div class="how-to">
                    <a :href="howTo">{{ $t('message.howto') }}</a>
                </div>
                <div class="bottom">
                    <div>
                        <img src="/img/zombie/fire.svg" />
                        <p>{{ totalSupply }}</p>
                    </div>
                    <p>{{ $t("message.left") }}</p>
                </div>
            </div>
            <Data></Data>
            <company></company>
            <Footer></Footer>
        </div>
        <notifications v-if="notify" :error="notify_error" :text="notify_text" v-on:close="notify = $event"></notifications>
    </div>
</template>

<script>
    import Header from "../components/Header.vue";
    import Data from "../components/Zombie/Data.vue";
    import Company from "../components/Company.vue";
    import Footer from "../components/Footer.vue";
    import Notifications from "../components/Notifications.vue";

    import { getSupply } from "../helpers/supply";

    import { ProviderKeeper } from '@waves/provider-keeper';
    import { ProviderCloud } from '@waves.exchange/provider-cloud';

    export default {
        name: "Zombie",
        data(){
            return {
                connect: false,
                punks_supply: 0,
                inviteKey: "",
                notify: false,
                notify_error: false,
                notify_text: "",
                howTo: "https://zombie.wavespunks.com/how"
            }
        },
        components: {
            Header,
            Data,
            Company,
            Footer,
            Notifications
        },
        async mounted() {
            let params = this.$route.query["id"];
            let inviteKey = window.localStorage.getItem("inviteKey");
            if (params != undefined) {
                this.inviteKey = params;
                window.localStorage.setItem("inviteKey", params);
            } else if (inviteKey != null) {
                this.inviteKey = inviteKey;
            }

            this.punks_supply = await getSupply(window.zombieAddress);

            setInterval(async () => {
                this.punks_supply = await getSupply(window.zombieAddress);
            }, 50000);
        },
        computed: {
            totalSupply() {
                return 1000 - this.punks_supply;
            },
            price() {
                return (parseInt(this.punks_supply / 200) + 1) * 3;
            }
        },
        watch: {
            "$i18n.locale": function (newVal, _) {
                const l = newVal;
                // how-tos url on tilda aren't OK
                if (l === 'en') {
                    this.howTo = this.howTo.split('_')[0];
                    return;
                }
                this.howTo = l ? this.howTo + `_${l}` : this.howTo;
            }
        },
        methods: {
            login() {
                const data = JSON.parse(window.localStorage.getItem("loginChoice"));
                if (!data) {
                    this.connect = true;
                } else {
                    this.connect = false;
                    this.mint();
                }
            },
            async connectKeeper() {
                if (window.signer) {
                    const authData = { data: 'https://mint.wavespunks.com/' };
                    await window.signer.setProvider(new ProviderKeeper(authData)).then(res => {
                        console.log(res);
                    }).catch(error => {
                        console.error(error);
                        this.notify = true;
                        this.notify_error = true;
                        this.notify_text = error;
                    });

                    await window.signer.login().then(res => {
                        console.log(res);

                        let data = {};
                        data.choice = "keeper";
                        data.address = res.address;
                        data.publicKey = res.publicKey;
                        window.localStorage.setItem("loginChoice", JSON.stringify(data));

                        this.login();
                    }).catch(error => {
                        console.error(error);
                        this.notify = true;
                        this.notify_error = true;
                        this.notify_text = error;
                    });
                }
            },
            async connectEmail() {
                if (window.signer) {
                    window.signer.setProvider(new ProviderCloud());
                    await window.signer.login().then(res => {
                        console.log(res);

                        let data = {};
                        data.choice = "email";
                        data.address = res.address;
                        data.publicKey = res.publicKey;
                        window.localStorage.setItem("loginChoice", JSON.stringify(data));

                        this.login();
                    }).catch(error => {
                        console.error(error);
                        this.notify = true;
                        this.notify_error = true;
                        this.notify_text = error;
                    });
                }
            },
            async mint() {
                const data = JSON.parse(window.localStorage.getItem("loginChoice"));

                if (data.choice == "keeper") {
                    const authData = { data: 'https://mint.wavespunks.com/' };
                    await window.signer.setProvider(new ProviderKeeper(authData)).then(res => {
                        console.log(res);
                    }).catch(error => {
                        console.error(error);
                    });
                } else if (data.choice == "email") {
                    window.signer.setProvider(new ProviderCloud());
                }

                await window.signer.invoke({
                    dApp: window.zombieAddress,
                    fee: 900000,
                    payment: [{
                        assetId: 'WAVES',
                        amount: (parseInt(this.punks_supply / 200) + 1) * 300000000,
                    }],
                    call: {
                        function: 'mint',
                        args: [{
                            type: 'string',
                            value: this.inviteKey,
                        }],
                    },
                }).broadcast().then(res => {
                    console.log(res);
                    this.notify = true;
                    this.notify_error = false;
                    this.notify_text = "Your transaction has been broadcast to network!";
                }).catch(error => {
                    console.error(error);
                    this.notify = true;
                    this.notify_error = true;
                    this.notify_text = error.message;
                });

                this.punks_supply = await getSupply(window.zombieAddress);
            }
        }
    }
</script>

<style scoped>
    @import '../assets/styles/mint.css';

    @media only screen and (max-width: 550px) {
        .zombie-color {
            padding-top: 20px !important;
        }
    }

    .zombie {
        background: linear-gradient(180deg, #030022 0%, rgba(3, 0, 34, 0.56) 49.48%, #030022 100%);
        background-image: url('/img/zombie/back.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 100vh;
        font-family: 'Manrope';
        font-style: normal;
    }

    .zombie-color {
        background: linear-gradient(180deg, #030022 0%, rgba(3, 0, 34, 0.56) 49.48%, #030022 100%);
        min-height: 100vh;
        padding-top: 90px;
    }

    .links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .links > a {
        font-weight: 400;
        font-size: 19px;
        line-height: 31px;
        margin-right: 20px;
    }

    .links > a, .links > a:hover, .links > a:active {
        text-decoration: none;
        color: white;
    }

    .mint > div:nth-child(4) > button {
        background: #FF00CF;
    }
</style>
