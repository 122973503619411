<template>
    <div class="selector">
        <div class="selector__dropdown">
            <span>
                <img height="24px" width="24px" :src="selectedLang.img" alt="">
                {{ selectedLang.name.toUpperCase() }}
            </span>
            <ul class="dropdown__content">
                <li
                    v-for="lang in langs"
                    :key="lang.name"
                    @click="changeLocale(lang)"
                ><img height="24px" width="24px" :src="lang.img" alt="">{{ lang.name.toUpperCase() }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "SelectLang",
    data() {
        return {
            selectedLang: {
                name: "en",
                img: '/img/icon_en.svg'
            },
            langs: [
                {
                    name: "en",
                    img: '/img/icon_en.svg'
                },
                {
                    name: "es",
                    img: '/img/icon_es.svg'
                },
            ]
        };
    },
    beforeMount() {
        const l = this.$route.params?.locale;
        const lang = this.langs.find((e) => e.name === l);
        this.changeLocale(lang ?? this.langs[0]);
    },
    methods: {
        changeLocale(locale) {
            this.selectedLang = locale;
            this.$i18n.locale = locale.name;
        }
    }

}
</script>

<style scoped>
    .selector {
        color: white;
        font-size: 19px;
        line-height: 31px;
        height: 31px;
        width: 4rem;
    }
    .selector__dropdown {
        position: relative;
        display: inline-block;
    }
    .selector__dropdown > span {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;
    }

    .selector__dropdown:hover .dropdown__content {
        display: block;
    }
    .dropdown__content {
        position: absolute;
        display: none;
        right: 0px;
        top: 10px;
        z-index: 111;
    }

    ul {
        list-style-type: none;
    }
    ul > li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
        cursor: pointer;
    }
</style>
