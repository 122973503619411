<template>
    <div class="company">
        <img @click="openTab('https://wavesassociation.org')" src="/img/zombie/waves-association.svg" />
        <img @click="openTab('https://picsell.art')" src="/img/zombie/psc.svg" />
    </div>
</template>

<script>
export default {
    name: "Company",
    methods: {
        openTab(dst) {
            window.open(dst)
        }
    }
};
</script>

<style scoped>
.company {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 60px;
}

.company > img {
    cursor: pointer;
}

.company > img:first-child {
    margin-right: 90px;
}
</style>
