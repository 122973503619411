<template>
    <div class="home">
        <a href="/#">
            <img src="/img/waves-punks.svg" />
            SOLD OUT
        </a>
        <a href="/zombie">
            <img src="/img/waves-punks-zombie.svg" />
            FOR SALE
        </a>
        <a href="/mad-empire">
            <img src="/img/waves-mad-punks.svg" />
            FOR SALE
        </a>
    </div>
</template>

<script>
    export default {
        name: "Home"
    }
</script>

<style scoped>
    @media only screen and (max-width: 767px) {
        .home {
            flex-direction: column !important;
        }
    }

    .home {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        font-family: 'Manrope';
        font-style: normal;
    }

    .home > a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
    }

    .home > a, .home > a:hover, .home > a:active {
        text-decoration: none;
        color: black;
    }

    .home > a > img {
        margin-bottom: 20px;
    }

    .home > a:first-child > img {
        filter: blur(2px);
    }
</style>
