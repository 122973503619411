<template>
    <header>
        <div class="logo">
            <a :href="url">
                <img :src="img" />
            </a>
        </div>
        <div class="links">
            <select-lang></select-lang>
            <a href="https://wavesmarketplace.com/">{{ $t("message.mypunks") }}</a>
            <a id="twitter" target="_blank" href="https://twitter.com/WavesPunks">
                <img src="/img/mad/twitter.svg" />
            </a>
            <a id="telegram" target="_blank" href="https://t.me/wavespunks_en">
                <img src="/img/mad/telegram.svg" />
            </a>
        </div>
    </header>
</template>

<script>
import SelectLang from './SelectLang.vue';

export default {
    name: "Header",
    props: ["url", "img"],
    components: {
        SelectLang,
    }
};
</script>

<style scoped>
@media only screen and (max-width: 550px) {
    header {
        flex-direction: column-reverse !important;
    }
}

header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
    margin: auto;
    margin-bottom: 40px;
}

.links > select {
    background: none;
    background-color: rgba(100, 100, 100, .0);
    color: white;
    border: none;
    margin-right: 15px;
    font-weight: 400;
    font-size: 19px;
    line-height: 31px;
    cursor: pointer;
}
</style>
